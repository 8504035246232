import React, { useRef, useEffect, useState } from "react";
import Hls from "hls.js";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useSelector, useDispatch } from "react-redux";
import {
  hidePopupVideoPlayer,
  openModalOptionVideo,
  updatePlaytime,
  updatePlaytimeLastWeek,
  updatePlaytimeLastWeekSelected,
} from "../redux/exerciseVideos";
import {
  completeVideoPlayPercentage,
  minimumVideoPlayPercentage,
  updateFrequency,
} from "../constants/defaultValues";

const VideoPlayerByteArk = ({
  url,
  day_number,
  video_number,
  selectedVDO,
  lastWeekVDO_click,
  lastWeekVDOAll,
  lastWeekStart,
  selectExerciseVideoLastWeek,
  isCurrentWeek,
  backupVideo, // ใช้ backupVideo เพื่อตรวจสอบว่าใช้ Video.js หรือ Hls.js
}) => {
  const dispatch = useDispatch();
  const hidePopUpVideoPlayer = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.hidePopUpVideoPlayer : ""
  );
  const exerciseVideo = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.exerciseVideo : ""
  );
  const all_exercise_activity = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.all_exercise_activity : ""
  );
  const exerciseVideoLastWeek = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.exerciseVideoLastWeek : ""
  );
  const user = useSelector(({ authUser }) => (authUser ? authUser.user : ""));

  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const [videoCurrDuration, setVideoCurrDuration] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [prevPlayTime, setPrevPlayTime] = useState(0);
  const [availableQualities, setAvailableQualities] = useState([]);
  const [currentQuality, setCurrentQuality] = useState(backupVideo == "main" ? "Auto" : -1);
  const [hlsInstance, setHlsInstance] = useState(null); // สำหรับ Hls.js


  const destroyCurrentPlayer = () => {
    if (hlsInstance) {
      hlsInstance.destroy();
      setHlsInstance(null);
    }
    if (player) {
      if (player.current) {
        player.current.pause();
        player.current.dispose();
        player.current = null;
      }
    }
  };


  useEffect(() => {
    const videoElement = videoRef.current;
    const savedPlayTime = localStorage.getItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`);

    if (backupVideo == "backup1") {
      // ใช้ Video.js
      if (videoElement) {
        const playerInstance = videojs(videoElement, {
          controls: true,
          autoplay: true,
          preload: "auto",
          controlBar: {
            fullscreenToggle: true,
          },
          sources: [
            {
              src: url,
              type: "application/x-mpegURL",
            },
          ],
        });

        playerInstance.on("loadedmetadata", () => {
          if (savedPlayTime) {
            playerInstance.currentTime(parseFloat(savedPlayTime));
          }
          setVideoDuration(playerInstance.duration());
        });

        playerInstance.on("timeupdate", () => {
          setVideoCurrDuration(playerInstance.currentTime());
        });

        playerInstance.on("ended", () => {
          setVideoEnded(true);
        });

        setPlayer(playerInstance);

        return () => {
          if (playerInstance) {
            playerInstance.dispose();
          }
        };
      }
    } else {
      // ใช้ Hls.js
      if (videoElement) {
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(url);
          hls.attachMedia(videoElement);

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            const levels = hls.levels.map((level, index) => ({
              label: `${level.height}p`,
              index: index,
            }));
            setAvailableQualities([{ label: "Auto", index: -1 }, ...levels]);
            setHlsInstance(hls);
            if (savedPlayTime) {
              videoElement.currentTime = parseFloat(savedPlayTime);
            }
            videoElement.play();
          });

          hls.on(Hls.Events.ERROR, (event, data) => {
            console.error("HLS error:", data);
          });
        } else if (videoElement.canPlayType("application/vnd.apple.mpegurl")) {
          videoElement.src = url;
          videoElement.addEventListener("canplay", () => {
            if (savedPlayTime) {
              videoElement.currentTime = parseFloat(savedPlayTime);
            }
            videoElement.play();
          });
        }

        videoElement.addEventListener("ended", () => {
          setVideoEnded(true);
        });

        videoElement.addEventListener("loadedmetadata", () => {
          setVideoDuration(videoElement.duration);
        });

        videoElement.addEventListener("timeupdate", () => {
          setVideoCurrDuration(videoElement.currentTime);
        });
      }
    }
  }, [url, backupVideo]);

  // อัปเดตเวลาเล่นเมื่อเวลาของวิดีโอเปลี่ยนแปลง
  useEffect(() => {
    if (videoCurrDuration && videoDuration) {
      const diffTime = Math.abs(videoCurrDuration - prevPlayTime);
      if (diffTime >= updateFrequency) {
        setPrevPlayTime(videoCurrDuration);
        if (videoCurrDuration / videoDuration >= 0.75) {
          updatePlayTime();
        }
      }
    }

    if (videoCurrDuration) {
      if (videoCurrDuration / videoDuration >= 0.75) {
        localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, 0);
      } else {
        localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, videoCurrDuration);
      }

    }
  }, [videoCurrDuration]);



  // ฟังก์ชันสำหรับอัปเดต playtime
  /*   const updatePlayTime = () => {
      const percentageWatched = (videoCurrDuration / videoDuration) * 100;
  
       if (percentageWatched < minimumVideoPlayPercentage) return;
   
      if (lastWeekVDO_click === "show") {
        if (!lastWeekVDOAll) {
          const tempExerciseVideoLastWeek = [...exerciseVideoLastWeek];
          tempExerciseVideoLastWeek[day_number][video_number] = {
            ...tempExerciseVideoLastWeek[day_number][video_number],
            play_time: videoCurrDuration,
            duration: videoDuration,
          };
          dispatch(
            updatePlaytimeLastWeek(
              user.user_id,
              user.start_date,
              user.expire_date,
              day_number,
              video_number,
              videoCurrDuration,
              videoDuration,
              tempExerciseVideoLastWeek
            )
          );
        } else {
          const tempExerciseVideoLastWeekSelect = [...selectExerciseVideoLastWeek];
          const tempExerciseVideoLastWeekAll = [...all_exercise_activity];
          tempExerciseVideoLastWeekSelect[day_number][video_number] = {
            ...tempExerciseVideoLastWeekSelect[day_number][video_number],
            play_time: videoCurrDuration,
            duration: videoDuration,
          };
          tempExerciseVideoLastWeekAll[lastWeekStart - 1].activities = JSON.stringify(tempExerciseVideoLastWeekSelect);
          dispatch(
            updatePlaytimeLastWeekSelected(
              user.user_id,
              user.start_date,
              user.expire_date,
              day_number,
              video_number,
              videoCurrDuration,
              videoDuration,
              tempExerciseVideoLastWeekAll,
              lastWeekStart
            )
          );
        }
      } else {
        if (!isCurrentWeek) {
          const tempExerciseVideoLastWeekSelect = [...selectExerciseVideoLastWeek];
          const tempExerciseVideoLastWeekAll = [...all_exercise_activity];
          tempExerciseVideoLastWeekSelect[day_number][video_number] = {
            ...tempExerciseVideoLastWeekSelect[day_number][video_number],
            play_time: videoCurrDuration,
            duration: videoDuration,
          };
          tempExerciseVideoLastWeekAll[lastWeekStart - 1].activities = JSON.stringify(tempExerciseVideoLastWeekSelect);
          dispatch(
            updatePlaytimeLastWeekSelected(
              user.user_id,
              user.start_date,
              user.expire_date,
              day_number,
              video_number,
              videoCurrDuration,
              videoDuration,
              tempExerciseVideoLastWeekAll,
              lastWeekStart
            )
          );
        } else {
          const tempExerciseVideo = [...exerciseVideo];
          tempExerciseVideo[day_number][video_number] = {
            ...tempExerciseVideo[day_number][video_number],
            play_time: videoCurrDuration,
            duration: videoDuration,
          };
          dispatch(
            updatePlaytime(
              user.user_id,
              user.start_date,
              user.expire_date,
              day_number,
              video_number,
              videoCurrDuration,
              videoDuration,
              tempExerciseVideo
            )
          );
        }
      }
    };
   */

  const updatePlayTime = () => {

    const tempExerciseVideoLastWeekSelect = [...selectExerciseVideoLastWeek];
    const tempExerciseVideoLastWeekAll = [...all_exercise_activity];
    tempExerciseVideoLastWeekSelect[day_number][video_number] = {
      ...tempExerciseVideoLastWeekSelect[day_number][video_number],
      play_time: videoDuration,
      duration: videoDuration,
    };
    tempExerciseVideoLastWeekAll[lastWeekStart - 1].activities =
      JSON.stringify(tempExerciseVideoLastWeekSelect);





    /* if (!isCurrentWeek) { */ // เเบบเดิม  //updatePlayTime ของผู้ใช้ต่ออายุดูย้อนหลัง

    if (tempExerciseVideoLastWeekAll.length != lastWeekStart) {
      console.log("updatePlayTime ของผู้ week ย้อนหลัง", isCurrentWeek);
      //updatePlayTime ของผู้ week ย้อนหลัง


      dispatch(
        updatePlaytimeLastWeekSelected(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          videoDuration,
          videoDuration,
          tempExerciseVideoLastWeekAll,
          lastWeekStart
        )
      );
    } else {

      console.log("updatePlayTime  ของผู้ week ปัจจุบัน ", isCurrentWeek);
      //updatePlayTime ของผู้ใช้ต่ออายุดูคลิปปัจจุบัน ไม่ได้ใช้เเล้ว
      // updatePlayTime ของผู้ week ปัจจุบัน 
      const tempExerciseVideo = [...exerciseVideo];
      tempExerciseVideo[day_number][video_number] = {
        ...tempExerciseVideo[day_number][video_number],
        play_time: videoDuration,
        duration: videoDuration,
      };

      console.log("updatePlaytime");

      dispatch(
        updatePlaytime(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          videoDuration,
          videoDuration,
          tempExerciseVideo
        )
      );
    }
  };



  const handleQualityChange = (qualityIndex) => {
    if (backupVideo == "backup1") {
      if (player) {
        if (qualityIndex === "Auto") {
          player.src({ src: url, type: "application/x-mpegURL" });
        } else {
          player.src({ src: url, type: "application/x-mpegURL", resolution: qualityIndex });
        }
        setCurrentQuality(qualityIndex);
        player.play();
      }
    } else {
      if (hlsInstance) {
        if (qualityIndex === -1) {
          hlsInstance.currentLevel = -1; // Auto quality
        } else {
          hlsInstance.currentLevel = qualityIndex; // Manual quality
        }
        setCurrentQuality(qualityIndex);
      }
    }
  };

  const handleVideoClose = () => {
    if (backupVideo == "backup1") {
      if (player) {
        player.pause();
      }
    } else {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.pause();
      }
    }
    destroyCurrentPlayer();
    dispatch(hidePopupVideoPlayer(true));
  };

  const startPlayNew = () => {
    // Reset the saved playtime in local storage
    localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, 0);

    if (backupVideo == "backup1") {
      // If using Video.js, reset the player to the start
      if (player) {
        player.currentTime(0);  // Reset the playtime to 0
        player.play();  // Start playback
      }
    } else {
      // If using HLS.js, reset the HTML5 video element to the start
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.currentTime = 0;  // Reset the playtime to 0
        videoElement.play();  // Start playback
      }
    }
  };
  return (
    <div>
      <div style={{ position: "relative" }}>
        <video ref={videoRef} className={backupVideo == "backup1" ? "video-js" : "trailer-video-hal"} controls playsInline />
        {backupVideo == "main" && <div style={{ display: "flex", position: "absolute", bottom: "-55px", left: "24px" }}>
          <label htmlFor="qualitySelect" style={{ marginRight: "8px", color: "#FFFFFF" }}>Quality: </label>

          <select
            id="qualitySelect"
            value={currentQuality}
            /* onChange={(e) => handleQualityChange(backupVideo ? e.target.value : parseInt(e.target.value))} */
            onChange={(e) => handleQualityChange(parseInt(e.target.value))}
          >
            {availableQualities.map((quality, index) => (
              <option key={index} value={quality.index}>
                {quality.label}
              </option>
            ))}
          </select>
        </div>}
        <div style={{ display: "flex", position: "absolute", bottom: "-60px", left: backupVideo == "main" ? "184px" : "24px" }}>
          <button type="button" class="box-random " onClick={startPlayNew}>เริ่มใหม่</button>

        </div>

      </div>
      <img alt="Close" src="../assets/img/thumb/close.png" className="close" onClick={handleVideoClose} />
    </div>
  );
};

export default VideoPlayerByteArk;
