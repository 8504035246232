import React, { useRef, useEffect, useState } from "react";
import Hls from "hls.js";
import { useSelector, useDispatch } from "react-redux";
import {
  hidePopupVideoPlayer,
  setHidePopupVideoPlayerList,
  setEndedVideoPlayerList,
  updatePlaytime,
  updatePlaytimeLastWeek,
  updatePlaytimeLastWeekSelected,
} from "../redux/exerciseVideos";
import {
  completeVideoPlayPercentage,
  minimumVideoPlayPercentage,
  updateFrequency,
} from "../constants/defaultValues";

const VideoPlayerListByteArk = ({
  url,
  day_number,
  video_number,
  selectedVDO,
  lastWeekVDO_click,
  lastWeekVDOAll,
  lastWeekStart,
  selectExerciseVideoLastWeek,
  isCurrentWeek,
}) => {
  const dispatch = useDispatch();
  const hidePopUpVideoPlayer = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.hidePopUpVideoPlayer : ""
  );
  const exerciseVideo = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.exerciseVideo : ""
  );
  const all_exercise_activity = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.all_exercise_activity : ""
  );
  const exerciseVideoLastWeek = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.exerciseVideoLastWeek : ""
  );
  const endedVideoPlayerList = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.endedVideoPlayerList : ""
  );
  const user = useSelector(({ authUser }) => (authUser ? authUser.user : ""));
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false); // เพิ่ม state สำหรับตรวจสอบว่าวีดีโอถูกดูจบหรือไม่
  const [videoCurrDuration, setVideoCurrDuration] = useState(0); // เพิ่ม state สำหรับเก็บระยะเวลาที่เล่นไปของวีดีโอ
  const [videoDuration, setVideoDuration] = useState(0); // เพิ่ม state สำหรับเก็บความยาวของวีดีโอ
  const [prevPlayTime, setPrevPlayTime] = useState(0);
  const [hlsInstance, setHlsInstance] = useState(null);
  const [availableQualities, setAvailableQualities] = useState([]);
  const [currentQuality, setCurrentQuality] = useState(-1); // ค่าเริ่มต้นเป็น -1 เพื่อแสดงว่าเป็น Auto





  useEffect(() => {
    addEventListenerVideo();

    setVideoCurrDuration(0);
    setVideoEnded(false);
    dispatch(setEndedVideoPlayerList(false));
  }, [selectedVDO]);


  const addEventListenerVideo = () => {
    const video = videoRef.current; // Use videoRef.current
    const savedPlayTime = localStorage.getItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`);

    if (video && selectedVDO) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(selectedVDO?.url); // Use the URL passed in props
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const levels = hls.levels.map((level, index) => ({
            label: `${level.height}p`,
            index: index,
          }));
          setAvailableQualities([{ label: "Auto", index: -1 }, ...levels]);
          setHlsInstance(hls);
          // Restore the playback time if saved
          if (savedPlayTime) {
            video.currentTime = parseFloat(savedPlayTime); // Fix here: use videoRef.current
          }
          video.play();
        });
        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error("HLS error:", data);
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = selectedVDO?.url;

        video.addEventListener("canplay", () => {
          if (savedPlayTime) {
            video.currentTime = parseFloat(savedPlayTime); // Fix here: use videoRef.current
          }
          video.play();
        });
      }

      video.addEventListener("ended", () => {
        setVideoEnded(true);
      });

      video.addEventListener("loadedmetadata", () => {
        setVideoDuration(video.duration); // Update video duration state
      });

      video.addEventListener("timeupdate", () => {
        setVideoCurrDuration(video.currentTime); // Update current playback time
      });
    }
  };

  useEffect(() => {
    if (videoEnded) {
      //ส่่งไปบอกหน้า videoList2 ว่าวีดีโอเล่นจบ
      dispatch(setEndedVideoPlayerList(true));
    }
  }, [videoEnded]);

  useEffect(() => {
    //เช็คคลิปตอน 99.9% แทนการเช็ควีดีโอจบ
    if (videoCurrDuration / videoDuration >= 0.999) {
      setVideoEnded(true);
    }
    //ทำการหน่วงเวลาตาม updateFrequency เพื่อยิง updatePlayTime
    /*     const diffTime = Math.abs(videoCurrDuration - prevPlayTime);
        if (diffTime < updateFrequency) {
          return;
        } */
    setPrevPlayTime(videoCurrDuration);


    if (videoCurrDuration / videoDuration >= 0.75) {
      updatePlayTime();
    }

    //เช็คว่าถ้าดูวีดีโอยังไม่ถึง minimumVideoPlayPercentage ไม่ต้อง updatePlayTime
    //เช็คว่าถ้าเคยดูคลิปนั้นจบแล้ว ไม่ต้อง updatePlayTime
    /*    if (
         videoCurrDuration / videoDuration < minimumVideoPlayPercentage ||
         (selectedVDO?.play_time / selectedVDO?.duration >=
           completeVideoPlayPercentage)
       ) {
         return;
       } */

    if (videoCurrDuration) {
      if (videoCurrDuration / videoDuration >= 0.75) {
        localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, 0);
      } else {
        localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, videoCurrDuration);
      }

    }
  }, [videoCurrDuration]);

  const updatePlayTime = () => {

    const tempExerciseVideoLastWeekSelect = [...selectExerciseVideoLastWeek];
    const tempExerciseVideoLastWeekAll = [...all_exercise_activity];
    tempExerciseVideoLastWeekSelect[day_number][video_number] = {
      ...tempExerciseVideoLastWeekSelect[day_number][video_number],
      play_time: videoDuration,
      duration: videoDuration,
    };
    tempExerciseVideoLastWeekAll[lastWeekStart - 1].activities =
      JSON.stringify(tempExerciseVideoLastWeekSelect);





    /* if (!isCurrentWeek) { */ // เเบบเดิม  //updatePlayTime ของผู้ใช้ต่ออายุดูย้อนหลัง
    console.log("week", tempExerciseVideoLastWeekAll.length, lastWeekStart);

    if (tempExerciseVideoLastWeekAll.length != lastWeekStart) {
      console.log("updatePlayTime ของผู้ week ย้อนหลัง", isCurrentWeek);
      //updatePlayTime ของผู้ week ย้อนหลัง


      dispatch(
        updatePlaytimeLastWeekSelected(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          videoDuration,
          videoDuration,
          tempExerciseVideoLastWeekAll,
          lastWeekStart
        )
      );
    } else {

      console.log("updatePlayTime  ของผู้ week ปัจจุบัน ", isCurrentWeek);
      //updatePlayTime ของผู้ใช้ต่ออายุดูคลิปปัจจุบัน ไม่ได้ใช้เเล้ว
      // updatePlayTime ของผู้ week ปัจจุบัน 
      const tempExerciseVideo = [...exerciseVideo];
      tempExerciseVideo[day_number][video_number] = {
        ...tempExerciseVideo[day_number][video_number],
        play_time: videoDuration,
        duration: videoDuration,
      };

      console.log("updatePlaytime");

      dispatch(
        updatePlaytime(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          videoDuration,
          videoDuration,
          tempExerciseVideo
        )
      );
    }
  };

  /*   const handleVideoClose = () => {
      const video = videoRef.current;
      const hls = new Hls();
  
      if (video) {
        video.pause();
        hls.destroy();
        selectedVDO = null;
        // และอื่น ๆ ที่คุณต้องการให้เกิดขึ้นเมื่อปิดวีดีโอ
      }
  
      //สั่ง set ตัวแปรใน redux และให้หน้า videoList ไปเช็ีคจากตัวแปรนั้นเพื่อซ่อน popup
      dispatch(setHidePopupVideoPlayerList(true));
    }; */
  const handleVideoClose = () => {
    const video = videoRef.current;

    if (video && hlsInstance) {
      video.pause();
      hlsInstance.destroy(); // Properly destroy the HLS instance if it exists
      selectedVDO = null;
    }

    // Hide popup
    dispatch(setHidePopupVideoPlayerList(true));
  };

  const handleQualityChange = (qualityIndex) => {
    if (hlsInstance) {
      if (qualityIndex === -1) {
        hlsInstance.currentLevel = -1; // ตั้งค่าเป็น auto
      } else {
        hlsInstance.currentLevel = qualityIndex; // ตั้งค่าระดับคุณภาพตาม index ที่เลือก
      }
      // อัปเดต currentQuality ให้เป็นค่า index ที่เลือก (เพื่อแสดงค่าใน dropdown)
      setCurrentQuality(qualityIndex);
    }
  };

  const startPlayNew = () => {
    // Reset the saved playtime in local storage
    localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, 0);


    // If using HLS.js, reset the HTML5 video element to the start
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.currentTime = 0;  // Reset the playtime to 0
      videoElement.play();  // Start playback
    }


  };


  return (
    <div>
      <div style={{ position: "relative" }}>
        <video id="videoPlayerList" className="trailer-video-hal" ref={videoRef} controls playsInline />
        <div style={{
          bottom: "-50px",
          display: "flex",
          position: "absolute",

          left: "24px",
        }}>
          <label htmlFor="qualitySelect" style={{ marginRight: "8px", color: "#FFFFFF" }}>Quality: </label>
          <select
            id="qualitySelect"
            value={currentQuality}
            onChange={(e) => handleQualityChange(parseInt(e.target.value))}
          >
            {availableQualities.map((quality, index) => (
              <option key={index} value={quality.index}>
                {quality.label}
              </option>
            ))}
          </select>
        </div>
        <div style={{ display: "flex", position: "absolute", bottom: "-60px", left: "184px" }}>
          <button type="button" class="box-random" onClick={() => startPlayNew()}>เริ่มใหม่</button>

        </div>
      </div>

      <img
        alt=""
        src="../assets/img/thumb/close.png"
        className="close"
        id="autoClickLink"
        onClick={handleVideoClose}
      ></img>
    </div>
  );
};

export default VideoPlayerListByteArk;


